<template>
    <v-container>
        <page-filters-section>
            <h1>Contracts info</h1>
            <span>The list of contracts we are using for Wizarre.</span>
        </page-filters-section>

        <div class="page-content">
            <v-row dense>
                <v-col cols="6" sm="4" v-for="con in contracts" :key="con.name" v-if="con.address.length">
                    <v-card class="contract-info">
                        <v-card-title>{{ con.name }}</v-card-title>
                        <v-card-text>
                            <a :href="bscUrl + con.address" target="_blank">
                                <img src="/img/icons/crypto/bscscan.ico" class="icon"/>
                                <span class="hidden-sm-and-down">{{ con.address }}</span>
                                <span class="hidden-md-and-up">{{ con.address |shortAddress }}</span>
                            </a>
                            <a :href="con.audit" target="_blank" v-if="con.audit">
                                <v-icon class="icon">mdi-security</v-icon>
                                Security audit
                            </a>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

    </v-container>
</template>


<script>
import LandLotteryWhitelist from "../components/land-lottery-whitelist";
import PageFiltersSection from "../components/page-filters-section";
import {vestingContractAddress} from "../services/contracts/vestingContract";
import {stakingContractAddress} from "../services/contracts/stakingContract";
import {landLotteryContractAddress} from "../services/contracts/landLotteryContract";
import {tokenContractAddress} from "../services/contracts/tokenContract";
import SummonContract from "../services/contracts/summonContract";
import {stakingMapAddress, stakingMapV2Address} from "../services/contracts/stakingMapV2Contract";
import WizardContract from "../services/contracts/wizardContract";
import LandContract from "../services/contracts/landContract";
import CardContract from "../services/contracts/cardContract";
import EnhancementContract from "@/services/contracts/enhancementContract";
import MarketContract from "../services/contracts/marketContract";

export default {
    components: {PageFiltersSection, LandLotteryWhitelist},
    props: [],
    data() {
        return {
            contracts: [
                {
                    name: 'SCRL Token (BEP-20)',
                    address: tokenContractAddress,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-token-security-review.pdf',
                },
                {
                    name: 'Wizard NFT (ERC-721)',
                    address: WizardContract.contractAddress,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-nft-wizard-security-audit.pdf',
                },
                {
                    name: 'Land NFT (ERC-721)',
                    address: LandContract.contractAddress,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-nft-wizard-security-audit.pdf',
                },
                {
                    name: 'Enhancement (ERC-1155)',
                    address: EnhancementContract.contractAddress,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-enhancements-security.pdf',
                },
                {
                    name: 'Claiming',
                    address: vestingContractAddress,
                },
                {
                    name: 'Wizard staking',
                    address: stakingContractAddress,
                },
                {
                    name: 'Land Lottery staking',
                    address: landLotteryContractAddress,
                },
                {
                    name: 'Staking Map',
                    address: stakingMapAddress,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-enhancements-security.pdf',
                },
                {
                    name: 'Staking Map V2',
                    address: stakingMapV2Address,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-enhancements-security.pdf',
                },
                {
                    name: 'Card NFT (ERC-721)',
                    address: CardContract.contractAddress,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-nft-wizard-security-audit.pdf',
                },
                {
                    name: 'Ritual of Summoning',
                    address: SummonContract.contractAddress,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-summoning.pdf',
                },
                {
                    name: 'Marketplace',
                    address: MarketContract.contractAddress,
                    audit: 'https://cdn.wizarre.io/doc/wizarre-rd-auditors-marketplace-security-review.pdf',
                },
            ]
        }
    },
    mounted() {

    },
    computed: {
        web3Balance() {
            return this.web3.balance;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        bscUrl() {
            return process.env.VUE_APP_BLOCKCHAIN_BLOCK_EXPLORER_URL + 'address/';
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.page-content a {
    color: #ccc !important;
    display: flex;
    align-content: center;
}

.page-content a:hover {
    color: #fff !important;
}

.contract-info .icon {
    width: auto;
    height: 18px;
    font-size: 18px;
    margin: 0 5px 0 0;
}

@media only screen and (max-width: $md) {
    .v-card__title{
        font-size: 16px;
    }
    .container {
        //padding-left: 6px;
        //padding-right: 10px;
    }
}
</style>
