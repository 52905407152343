var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-filters-section", [
        _c("h1", [_vm._v("Contracts info")]),
        _c("span", [_vm._v("The list of contracts we are using for Wizarre.")]),
      ]),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            _vm._l(_vm.contracts, function (con) {
              return con.address.length
                ? _c(
                    "v-col",
                    { key: con.name, attrs: { cols: "6", sm: "4" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "contract-info" },
                        [
                          _c("v-card-title", [_vm._v(_vm._s(con.name))]),
                          _c("v-card-text", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.bscUrl + con.address,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: "/img/icons/crypto/bscscan.ico",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "hidden-sm-and-down" },
                                  [_vm._v(_vm._s(con.address))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "hidden-md-and-up" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("shortAddress")(con.address)
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            con.audit
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: con.audit,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("v-icon", { staticClass: "icon" }, [
                                      _vm._v("mdi-security"),
                                    ]),
                                    _vm._v(" Security audit "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e()
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }